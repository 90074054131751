@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  overflow: hidden; 
}

.page-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000; 
  overflow: hidden;
  padding-top: 5vh; 
}

.cycling-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 3s ease;
}

.cycling-background.current {
  opacity: 1;
}

.cycling-background.next {
  opacity: 0;
}

.content-wrapper {
  position: relative;
  z-index: 2;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 2vh 5vw;
  border-radius: 25px; 
  width: 60%;
  max-width: 1400px; 
  height: 65%; 
  max-height: 1000px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; 
}

.content-wrapper h1 {
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  text-align: center;
}

.content-wrapper h2 {
  color: #F0F8FF;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  font-size: clamp(1rem, 3vw, 1.8rem);
  text-align: center;
}

.logo {
  max-width: 200px;
  width: 30%;
  height: auto;
  margin-bottom: 20px;
}

.course-select-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin-top: 1px; 
}

.select-wrapper {
  position: relative;
  width: 100%;
}

.course-select {
  width: 100%;
  padding: 15px;
  padding-right: 40px;
  font-size: clamp(0.9rem, 2vw, 1.1rem);
  border: none;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.9);
  color: #333;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.select-wrapper::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231a2a6c' d='M2 4 L6 8 L10 4 Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: none;
  transition: all 0.3s ease;
}

.select-wrapper:hover::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%232a3a7c' d='M2 4 L6 8 L10 4 Z'/%3E%3C/svg%3E");
}

.course-select:focus {
  outline: none;
  box-shadow: 0 0 0 2px #1a2a6c;
}

.course-select:focus + .select-wrapper::after {
  transform: translateY(-50%) rotate(180deg);
}

.course-select option:disabled {
  color: #999;
}

.course-submit-btn {
  margin-top: 10px; 
  padding: 15px 30px;
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  background-color: #1a2a6c;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.course-submit-btn:hover:not(:disabled) {
  background-color: #2a3a7c;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.course-submit-btn:disabled {
  background-color: #999;
  cursor: not-allowed;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #1a2a6c, #b21f1f, #fdbb2d);
  margin-top: -10vh; 
}

button {
  padding: 15px 30px;
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  background-color: #1a2a6c;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

button:hover:not(:disabled) {
  background-color: #2a3a7c;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .content-wrapper {
    width: 90%;
    height: 90%;
    padding: 3vh 3vw;
  }
}

@media (max-width: 480px) {
  .content-wrapper {
    width: 95%;
    height: 95%;
    padding: 2vh 2vw;
  }
}
